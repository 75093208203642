import React, { useEffect, useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import startOfMonth from "date-fns/startOfMonth";

import "react-datepicker/dist/react-datepicker.css";
import "./_ingestionForm.scss";
import { formatISO } from "date-fns";

const startIngestion = async ({
  source,
  interval,
  startDate,
  endDate,
  action,
  additional,
}) => {
  const response = await axios.post(`/api/ingestion/${source}`, {
    interval,
    startDate: formatISO(startDate),
    endDate: formatISO(endDate),
    action,
    additional,
  });
  console.log(response.data);
};

const getActionList = (source) => {
  switch (source.toLowerCase()) {
    case "shipstation":
      return ["Orders", "Shipments"];
    case "aws_orders":
      return [
        "ListOrders",
        "RequestReport",
        "GetReportRequestList",
        "GetReportList",
        "GetReport",
        "GetMatchingProduct",
        "GetCompetitivePricingForSKU",
        "GetMyPriceForSKU",
        "GetProductCategoriesForSKU",
        "GetProductCategoriesForASIN"
      ];
    case "ping":
      return ["No Action"];
    default:
      return [];
  }
};

const StartIngestion = () => {
  const [source, setSource] = useState("");
  const [interval, setInterval] = useState();
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(Date.now);
  const [additional, setAdditional] = useState("{}");

  const [actionSet, setActionSet] = useState([]);
  const [action, setAction] = useState("");

  useEffect(() => {
    const sourceList = getActionList(source);
    setActionSet(sourceList);
    setAction(sourceList.length > 0 ? sourceList[0] : "");
  }, [source, setActionSet]);

  return (
    <div className="ingestion-form">
      <div className="form-field">
        <label>Source</label>
        <select
          onChange={(e) => {
            setSource(e.currentTarget.value);
          }}
          value={source}
        >
          <option value="">-- Select --</option>
          <option value="ping">Ping</option>
          <option value="shipstation">ShipStation</option>
          <option value="aws_orders">Amazon MWS</option>
        </select>
      </div>
      <div className="form-field">
        <label>Action</label>
        <select
          onChange={(e) => {
            setAction(e.currentTarget.value);
          }}
          value={action}
        >
          {actionSet.map((o) => (
            <option key={`option_${o}`} value={o}>
              {o}
            </option>
          ))}
        </select>
      </div>
      <div className="form-field">
        <label>Interval</label>
        <select
          onChange={(e) => {
            setInterval(e.currentTarget.value);
          }}
          value={interval}
        >
          <option value="">Once</option>
          <option value="0 */60 * * * *">Every Hour</option>
          <option value="0 1 0 */1 * *">Every Day (At 12:01am)</option>
        </select>
      </div>
      <div className="form-field">
        <label>Start Date</label>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
        />
      </div>
      <div className="form-field">
        <label>End Date</label>
        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
      </div>
      <div className="form-field">
        <label>Additional</label>
        <textarea
          onChange={(e) => setAdditional(e.currentTarget.value)}
          value={additional}
        ></textarea>
      </div>
      <button
        onClick={() => {
          startIngestion({
            source,
            interval,
            startDate,
            endDate,
            action,
            additional,
          });
        }}
      >
        Start
      </button>
    </div>
  );
};

export default StartIngestion;
