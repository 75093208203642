import { useState } from "react";
import "./App.scss";
import StartIngestion from "./components/ingestion";

function App() {
  const [showForm, setShowForm] = useState(false);

  return (
    <div className="App">
      <header className="App-header">Gorilla MQ</header>
      <div className="App-container">
        <button
          onClick={() => {
            setShowForm(!showForm);
          }}
        >
          {showForm ? "Close" : "Add New Action"}
        </button>

        {showForm && (
          <div className="ingestion-modal">
            <StartIngestion />
          </div>
        )}

        <div className="bull-frame">
          <iframe title="bull-iframe" src="/bull"></iframe>
        </div>
      </div>
    </div>
  );
}

export default App;
